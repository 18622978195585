import React, {FC, useEffect, useState} from "react";
import Panel from "../../atoms/Panel";
import {LinkButton, Title} from "../../atoms/Typography";
import {getScorings, ScoringInfo} from "../../api/scoring";
import Loading from "../../atoms/Loading";
import {Column, Row} from "../../atoms/Layout";
import Button from "../../atoms/Button";
import {HorizontalSpaceFlex1} from "../../atoms/Space";
import {useNavigate} from "react-router-dom";

const Scorings: FC = () => {
    const [scorings, setScorings] = useState<ScoringInfo[] | undefined>(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        getScorings().then(setScorings)
    }, []);

    return <>
        <Title>Scoring Systems</Title>
        { scorings === undefined && <Panel>
            <Loading />
        </Panel> }
        { scorings && <Panel>
            <Column gap="medium">
                <Row vcenter>
                    <HorizontalSpaceFlex1 />
                    <Button onClick={() => navigate('/admin/scoring/new')}>Create New</Button>
                </Row>
                { scorings.map((scoring) => <Row vcenter key={scoring.id}>
                    <div>{scoring.name}</div>
                    <HorizontalSpaceFlex1 />
                    <Button onClick={() => navigate(`/admin/scoring/${scoring.id}`)}>Edit</Button>
                </Row> ) }
            </Column>
        </Panel> }
    </>


}

export default Scorings
