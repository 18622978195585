import axios, { AxiosError, isAxiosError } from 'axios';

export interface AuthResult {
    user: User
    token: string
}

export interface User {
    id: string
    email: string
    name: string
    roles: string[]
}

export interface RegisterCommand {
    email: string
    password: string
    name: string
    spaceName: string
}

export const register = async (command: RegisterCommand): Promise<AuthResult | null> => {
    const res = await axios.post('/api/auth/register', command)
    return res.data
}

export const getUser = async (): Promise<User | null> => {
    try {
        const res = await axios.get('/api/auth')
        return res.data
    } catch (error) {
        if (isAxiosError(error) && (error as AxiosError).response?.status === 401) {
            return null
        }
        throw error
    }
}

export const login = async (email: string, password: string): Promise<AuthResult | null> => {
    try {
        const res = await axios.post('/api/auth/login', { email, password })
        return res.data
    } catch (error) {
        if (isAxiosError(error) && (error as AxiosError).response?.status === 401) {
            return null
        }
        throw error
    }
}

export const logout = async (): Promise<void> => {
    await axios.post('/api/auth/logout')
}

export const changePassword = async (oldPassword: string, newPassword: string): Promise<boolean> => {
    try {
        await axios.post('/api/auth/change-password', { oldPassword, newPassword })
        return true
    } catch (error) {
        if (isAxiosError(error) && (error as AxiosError).response?.status === 401) {
            return false
        }
        throw error
    }
}

export const getUsers = async (): Promise<User[]> => {
    return (await axios.get('/api/users')).data
}

export const impersonateUser = async (email: string): Promise<void> => {
    await axios.get(`/api/auth/impersonate/${email}`)
}