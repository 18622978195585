import React, { FC } from 'react';
import { FeedbackResponse } from 'api/feedback';
import { Column, Row } from 'atoms/Layout';
import {Body, BodyBold, Label, Subtitle, Title} from 'atoms/Typography';
import SentimentGauge from 'atoms/SentimentGauge';
import { Sentiment, toSentiment } from 'model/feedback';
import InformationTooltip from '../molecules/InformationTooltip';
import {ScoringCategory, ScoringCategoryTopic} from "../api/scoring";

interface Props {
    feedback: FeedbackResponse[];
    scoringCategory: ScoringCategory;
}

const totalScoreByTopic = (feedback: FeedbackResponse[], topic: ScoringCategoryTopic) => {
    const relevantFeedback = feedback.filter(f => f.analysis.topics.some(t => t.id === topic.topicId));
    if (relevantFeedback.length === 0) {
        return 0;
    }
    return relevantFeedback.reduce((acc, f) => acc + f.analysis.sentimentScore, 0) * topic.weight / relevantFeedback.length;
}

const InsightScore: FC<Props> = ({ feedback, scoringCategory }) => {
    let totalWeight = scoringCategory.topics.reduce((acc, t) => acc + t.weight, 0);

    let averageSentiment = 0;
    if (totalWeight > 0 && feedback.length > 0) {
        const totalScore = scoringCategory.topics.reduce((acc, t) => acc + totalScoreByTopic(feedback, t), 0);
        averageSentiment = totalScore / totalWeight;
    }

    const sentiment = toSentiment(averageSentiment)


    return <Column>
        <div style={{ minWidth: 260}} />
        <Row size="full" nowrap gap="medium">
            <Column size="full" vcenter>
                <Row vcenter gap="small">
                    <BodyBold>{scoringCategory.name}</BodyBold>
                </Row>
                <Row vcenter gap="small">
                    <Body>{scoringCategory.description}</Body>
                </Row>
            </Column>
            <Column>
                <SentimentGauge sentimentScore={averageSentiment} />
            </Column>
        </Row>
    </Column>

}

export default InsightScore;
