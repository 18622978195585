import React, { FC, useEffect, useRef, useState } from 'react';
import { Emotion, FeedbackResponse, FeedbackResponseType, Gender, getFeedbackAudio } from '../api/feedback';
import Panel from '../atoms/Panel';
import { Channel } from '../api/channels';
import styled from 'styled-components';
import colors from '../atoms/colors';
import { Column, Row } from '../atoms/Layout';
import { Body, Hint, Label } from '../atoms/Typography';
import moment, { Moment } from 'moment';
import Pill from '../atoms/Pill';
import SentimentIcon from '../atoms/SentimentIcon/index';
import { toSentiment } from '../model/feedback';
import AudioPlayback from '../molecules/AudioPlayback';

const HorizontalDivider = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.borderSecondary};
`;

const VerticalDivider = styled.div`
    height: 24px;
    border-left: 1px solid ${colors.borderSecondary};
`;

interface Props {
    feedback: FeedbackResponse;
    channels: Channel[];
}

const GENDER_LABELS = {
    [Gender.MALE]: "Male",
    [Gender.FEMALE]: "Female"
}

const getAgeLabel = (age: number, createdAt: Moment, id: string, message: string) => {
    if (age < 25) return "18-24"
    if (age < 35) return "25-34"
    if (age < 45) return "35-44"
    if (age < 55) return "45-54"
    if (age < 65) return "55-64"
    return "65+"
}

const EMOTION_LABELS = {
    [Emotion.HAPPY]: "Happy",
    [Emotion.SAD]: "Sad",
    [Emotion.ANGRY]: "Angry",
    [Emotion.NEUTRAL]: "Neutral",
}

const determinePromoterCategory = (score: number | null | undefined) => {
    if (score === undefined || score === null) return null;

    if (score < 7) return "NPS Detractor"
    if (score < 9) return "NPS Passive"
    return "NPS Promoter"
}

const languageDisplayNames = new Intl.DisplayNames([], { type: 'language' });

const extraValuePills = (extraValues: Record<string, string>) => {
    return Object.keys(extraValues).sort().map(key => `${key}: ${extraValues[key]}`)
}

const FeedbackItem: FC<Props> = ({ feedback, channels}) => {
    const [audio, setAudio] = useState<Blob | null>(null)
    const [playing, setPlaying] = useState<boolean>(false)
    const panelRef = useRef(null); // Create a ref for the Panel


    const sentiment = toSentiment(feedback.analysis.sentimentScore)
    const formattedDate = moment(feedback.createdAt).format('ddd D MMM, h:mm a');
    const channelName = channels.find(l => l.id === feedback.channelId)?.name;
    const languageLabel = languageDisplayNames.of(feedback.analysis.language)
    const genderLabel = feedback.analysis.gender && GENDER_LABELS[feedback.analysis.gender]
    const ageLabel = feedback.analysis.age && getAgeLabel(feedback.analysis.age, moment(feedback.createdAt), feedback.id, feedback.analysis.textContent)
    const emotionLabel = feedback.analysis.emotion && EMOTION_LABELS[feedback.analysis.emotion]
    const sortedTopicsByName = feedback.analysis.topics.sort((a, b) => a.name.localeCompare(b.name))
    const pills = [...sortedTopicsByName.map(t => t.name), determinePromoterCategory(feedback.promoterScore), ...extraValuePills(feedback.extraValues)].filter(value => value)

    useEffect(() => {
        if (!panelRef.current) return;

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting && feedback.type === FeedbackResponseType.VOICE && !audio) {
                    // Only fetch audio when the element is in view and it's a voice feedback and audio is not already fetched
                    getFeedbackAudio(feedback.id).then(setAudio);
                }
            });
        });

        observer.observe(panelRef.current);

        return () => {
            if (panelRef.current) {
                observer.unobserve(panelRef.current);
            }
        };
    }, [feedback, audio]);

    return <Panel ref={panelRef} style={{ flexShrink: 0 }}>
        <Column gap="medium">
            <Row vcenter gap="large" nowrap>
                <SentimentIcon sentiment={sentiment} active={!playing} size="large" onClick={() => setPlaying(!playing)} play={feedback.type === FeedbackResponseType.VOICE}/>
                <Column size="full">
                    {audio && <AudioPlayback audio={audio} playing={playing} onAudioEnded={() => setPlaying(false) }/> }
                    { feedback.type === FeedbackResponseType.TEXT && <Hint>This Feedback is text-only.</Hint> }
                </Column>
            </Row>
            <HorizontalDivider />
            <Body>
                {feedback.analysis.textContent}
            </Body>
            <HorizontalDivider />
            <Row gap="medium">
                <Label>{formattedDate}</Label>
                <VerticalDivider />
                <Label>{channelName}</Label>
                { languageLabel && <>
                    <VerticalDivider />
                    <Label>{languageLabel}</Label>
                    </>
                }
                { genderLabel && <>
                    <VerticalDivider />
                    <Label>{genderLabel}</Label>
                </>}
                { ageLabel && <>
                    <VerticalDivider />
                    <Label>{ageLabel}</Label>
                </>}
                { emotionLabel && <>
                    <VerticalDivider />
                    <Label>{emotionLabel}</Label>
                </>}
                { pills.length > 0 && <VerticalDivider /> }
                { pills.map((name, i) => <>
                    <Pill key={i}>{name}</Pill>
                </>)}
            </Row>
        </Column>
    </Panel>
}

export default FeedbackItem;
