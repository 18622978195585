import React, {useEffect, useState} from 'react';
import { getUser, User } from '../api/users';
import { getSpace, Space } from '../api/spaces';
import { getChannels, Channel } from '../api/channels';
import { FeedbackPage, getFeedbackPage } from '../api/feedbackPage';
import { FeedbackResponse, getAllFeedback } from '../api/feedback';
import {ContextField, getContextFields} from "../api/context";

interface LoadedValue<T> {
    loading: boolean
    loaded: boolean
    value: T | null
    load: (reload?: boolean) => Promise<void>
    setValue: (value: T) => void
    clear: () => void
}

const createLoadedValue = <T extends {}>(loader: () => Promise<T | undefined | null>, onChange?: () => void): LoadedValue<T> => {
    const [value, setValue] = useState<T | null>(null)
    const [loaded, setLoaded] = useState(false)
    const [loading, setLoading] = useState(false)

    const load = async (reload?: boolean) => {
        if (value && !reload) {
            return
        }

        setLoading(true)
        const newValue = await loader()
        setValue(newValue || null)
        setLoading(false)
        setLoaded(true)
        onChange?.()
    }

    const clear = () => {
        setValue(null)
        setLoaded(false)
        onChange?.()
    }

    return {
        loading,
        loaded,
        value,
        load,
        setValue,
        clear
    }
}

export interface AppContextValue {
    user: LoadedValue<User>
    space: LoadedValue<Space>
    contextFields: LoadedValue<ContextField[]>
    channels: LoadedValue<Channel[]>
    feedbackPage: LoadedValue<FeedbackPage>
    feedback: LoadedValue<FeedbackResponse[]>
}

export const AppContext = React.createContext<AppContextValue>(null as any)

export const useNewAppContext = (): AppContextValue => {
    const space = createLoadedValue<Space>(getSpace)
    const contextFields = createLoadedValue<ContextField[]>(getContextFields)
    const channels = createLoadedValue<Channel[]>(getChannels)
    const feedbackPage = createLoadedValue<FeedbackPage>(getFeedbackPage)
    const feedback = createLoadedValue<FeedbackResponse[]>(getAllFeedback)

    const clearAll = () => {
        space.clear()
        channels.clear()
        feedbackPage.clear()
        contextFields.clear()
        feedback.clear()
    }

    const result = {
        user: createLoadedValue<User>(getUser, clearAll),
        space,
        channels,
        feedbackPage,
        contextFields,
        feedback
    }

    useEffect(() => {
        const i = setInterval(() => {
            console.log("Reload feedback")
            if (result.user.loaded) {
                void result.feedbackPage.load(true)
            }
        }, 30 * 1000)
        console.log("Created reload interval", i)

        return () => clearInterval(i)
    }, [result]);


    return result
}

export const useAppContext = (): AppContextValue => {
    return React.useContext(AppContext)
}
