import {buildReport} from "./reportBuilder";
import {useMemo, useState} from "react";
import {TextAreaInput} from "../../../atoms/Input";
import {Column, Row} from "../../../atoms/Layout";
import {HorizontalSpaceFlex1} from "../../../atoms/Space";
import Button from "../../../atoms/Button";
import {ReportContent} from "./types";

const defaultReportData: ReportContent = {
    title: 'My Report',
    colors: {
        text: '#181C24',
        hint: '#A1A1A4',
        border: '#595C62',
        background: '#3d80e0',
        panel: '#FFFFFF',
        brand: '#4C46DC',
        negative: '#ff5a36',
        neutral: '#cfde00',
        positive: '#32d9c3',
        empty: '#C1C1C4',
        full: '#4C46DC',
    },
    executiveSummary: 'This is the executive summary',
    responseProfile: {
        overallSentiment: "Positive",
        negativeResponses: 10,
        neutralResponses: 20,
        positiveResponses: 30,
    },
    actionPlan: {
        title: "Brent Cross Connect Initiative",
        introduction: "**Brent Cross Connect** is a retailer-focused program to improve communication, streamline support, and enhance satisfaction. By offering a single point of contact, proactive measures, and fostering collaboration, it ensures transparency and value. This action plan leverages the initiative to address key themes from the Brent Cross Occupier Survey, delivering immediate improvements and long-term impact.",
        sections: [
            {
                title: "Enhancing Retailer Engagement and Communication",
                immediateActions: [
                    {
                        title: "Retailer Support Line",
                        description: "Launch a single contact point under the Brent Cross Connect Initiative, offering a dedicated email and phone line with guaranteed 24-48 hour response times and clear escalation protocols. ",
                    },
                    {
                        title: "Retailer Support Line",
                        description: "Launch a single contact point under the Brent Cross Connect Initiative, offering a dedicated email and phone line with guaranteed 24-48 hour response times and clear escalation protocols. ",
                    }
                ],
                nextLevelActions: [
                    {
                        title: "Retailer Support Line",
                        description: "Launch a single contact point under the Brent Cross Connect Initiative, offering a dedicated email and phone line with guaranteed 24-48 hour response times and clear escalation protocols. ",
                    },
                    {
                        title: "Retailer Support Line",
                        description: "Launch a single contact point under the Brent Cross Connect Initiative, offering a dedicated email and phone line with guaranteed 24-48 hour response times and clear escalation protocols. ",
                    }
                ],
                impact: "Enhanced communication and collaboration foster a stronger retailer community, improve relationships, and elevate the shopping experience."
            },
            {
                title: "Enhancing Retailer Engagement and Communication",
                immediateActions: [
                    {
                        title: "Retailer Support Line",
                        description: "Launch a single contact point under the Brent Cross Connect Initiative, offering a dedicated email and phone line with guaranteed 24-48 hour response times and clear escalation protocols. ",
                    },
                    {
                        title: "Retailer Support Line",
                        description: "Launch a single contact point under the Brent Cross Connect Initiative, offering a dedicated email and phone line with guaranteed 24-48 hour response times and clear escalation protocols. ",
                    }
                ],
                nextLevelActions: [
                    {
                        title: "Retailer Support Line",
                        description: "Launch a single contact point under the Brent Cross Connect Initiative, offering a dedicated email and phone line with guaranteed 24-48 hour response times and clear escalation protocols. ",
                    },
                    {
                        title: "Retailer Support Line",
                        description: "Launch a single contact point under the Brent Cross Connect Initiative, offering a dedicated email and phone line with guaranteed 24-48 hour response times and clear escalation protocols. ",
                    }
                ],
                impact: "Enhanced communication and collaboration foster a stronger retailer community, improve relationships, and elevate the shopping experience."
            },
            {
                title: "Enhancing Retailer Engagement and Communication",
                immediateActions: [
                    {
                        title: "Retailer Support Line",
                        description: "Launch a single contact point under the Brent Cross Connect Initiative, offering a dedicated email and phone line with guaranteed 24-48 hour response times and clear escalation protocols. ",
                    },
                    {
                        title: "Retailer Support Line",
                        description: "Launch a single contact point under the Brent Cross Connect Initiative, offering a dedicated email and phone line with guaranteed 24-48 hour response times and clear escalation protocols. ",
                    }
                ],
                nextLevelActions: [
                    {
                        title: "Retailer Support Line",
                        description: "Launch a single contact point under the Brent Cross Connect Initiative, offering a dedicated email and phone line with guaranteed 24-48 hour response times and clear escalation protocols. ",
                    },
                    {
                        title: "Retailer Support Line",
                        description: "Launch a single contact point under the Brent Cross Connect Initiative, offering a dedicated email and phone line with guaranteed 24-48 hour response times and clear escalation protocols. ",
                    }
                ],
                impact: "Enhanced communication and collaboration foster a stronger retailer community, improve relationships, and elevate the shopping experience."
            }
        ],
        conclusion: "The **Brent Cross Connect Initiative** is at the heart of this plan, integrating communication, collaboration, and proactive measures to address occupier concerns effectively. By enhancing engagement, strengthening the value proposition, elevating property standards, and fostering transparency, Brent Cross can position itself as a leading, collaborative, and value-driven retail destination."

    },
    scoreIndex: {
        title: "Bewonder* CX Score Index",
        description: "The Bewonder* CX Score Index combines quantitative and qualitative insights into a clear, weighted metric of occupier feedback. Benchmarked against the GRESB framework, it pinpoints key strengths and areas for improvement, delivering actionable insights to enhance satisfaction and performance.",
        scores: [
            {
                title: "Satisfaction with Communication",
                score: 6.9,
                basedOn: "Based on 75 responses",
                color: "positive",
                description: "Feedback on communication with the Centre Management Team at Brent Cross was mixed. While some praised responsiveness, others highlighted slow follow-ups, limited direct engagement, and issues with the app. Suggestions included more face-to-face communication, quicker responses, and reinstating amenities like an information desk and better signage.",
            },
            {
                title: "Satisfaction with Communication",
                score: 6.9,
                basedOn: "Based on 75 responses",
                color: "neutral",
                description: "Feedback on communication with the Centre Management Team at Brent Cross was mixed. While some praised responsiveness, others highlighted slow follow-ups, limited direct engagement, and issues with the app. Suggestions included more face-to-face communication, quicker responses, and reinstating amenities like an information desk and better signage.",
            },
            {
                title: "Satisfaction with Communication",
                score: 6.9,
                basedOn: "Based on 75 responses",
                color: "negative",
                description: "Feedback on communication with the Centre Management Team at Brent Cross was mixed. While some praised responsiveness, others highlighted slow follow-ups, limited direct engagement, and issues with the app. Suggestions included more face-to-face communication, quicker responses, and reinstating amenities like an information desk and better signage.",
            },
            {
                title: "Satisfaction with Communication",
                score: 6.9,
                basedOn: "Based on 75 responses",
                color: "positive",
                description: "Feedback on communication with the Centre Management Team at Brent Cross was mixed. While some praised responsiveness, others highlighted slow follow-ups, limited direct engagement, and issues with the app. Suggestions included more face-to-face communication, quicker responses, and reinstating amenities like an information desk and better signage.",
            },
            {
                title: "Satisfaction with Communication",
                score: 6.9,
                basedOn: "Based on 75 responses",
                color: "positive",
                description: "Feedback on communication with the Centre Management Team at Brent Cross was mixed. While some praised responsiveness, others highlighted slow follow-ups, limited direct engagement, and issues with the app. Suggestions included more face-to-face communication, quicker responses, and reinstating amenities like an information desk and better signage.",
            }
        ]
    },
    chartsSectionTitle: "Quantitative Analysis: Key Findings and Visual Data",
    leftColumnCharts: [
        {
            type: 'keyTopics',
            title: 'Key Topics',
            collapsible: false,
            description: "The Key Topics highlight the main focus areas from occupier feedback, showing the percentage of responses for each topic, such as value for money, issue resolution, and property condition. This helps identify key priorities for improvement.",
            totalResponses: 80,
            topics: [
                {
                    title: "Property Condition",
                    responses: 50
                },
                {
                    title: "Issue Resolution",
                    responses: 30
                },
                {
                    title: "Value for Money",
                    responses: 20
                },
                {
                    title: "Lease Flexibility",
                    responses: 0
                },
            ]
        },
        {
            type: "topicSentiment",
            title: "Topic Sentiment",
            description: "This chart displays the predominant sentiment (positive, neutral, or negative) associated with each of the 12 topics. It provides insights into how Brent Cross occupiers feel overall about each aspect of the Shopping Centre management, helping to identify areas of strength as well as opportunities for improvement. By analyzing the sentiment, we can better understand occupier satisfaction and target specific areas for enhancement.",
            topics: [
                {
                    title: "Property Condition",
                    positiveResponses: 30,
                    neutralResponses: 10,
                    negativeResponses: 10
                },
                {
                    title: "Issue Resolution",
                    positiveResponses: 5,
                    neutralResponses: 5,
                    negativeResponses: 20
                },
                {
                    title: "Value for Money",
                    positiveResponses: 5,
                    neutralResponses: 10,
                    negativeResponses: 5
                },
                {
                    title: "Lease Flexibility",
                    positiveResponses: 0,
                    neutralResponses: 0,
                    negativeResponses: 0
                }
            ]
        }
    ],
    rightColumnCharts: [
        {
            type: 'bar',
            title: "Communication with the Centre Management Team",
            data: [
                {
                    label: 'Very Poor',
                    value: 5,
                    color: 'negative'
                },
                {
                    label: 'Poor',
                    value: 10,
                    color: 'negative'
                },
                {
                    label: 'Average',
                    value: 15,
                    color: 'neutral'
                },
                {
                    label: 'Good',
                    value: 20,
                    color: 'positive'
                },
                {
                    label: 'Excellent',
                    value: 25,
                    color: 'positive'
                }
            ]
        },
        {
            type: 'netPromoterScore',
            title: "Net Promoter Score",
            detractors: 10,
            passives: 20,
            promoters: 30
        }
    ],
    wideCharts: [
        {
            type: 'bar',
            title: "Communication with the Centre Management Team",
            data: [
                {
                    label: 'Very Poor',
                    value: 5,
                    color: 'negative'
                },
                {
                    label: 'Poor',
                    value: 10,
                    color: 'negative'
                },
                {
                    label: 'Average',
                    value: 15,
                    color: 'neutral'
                },
                {
                    label: 'Good',
                    value: 20,
                    color: 'positive'
                },
                {
                    label: 'Excellent',
                    value: 25,
                    color: 'positive'
                }
            ]
        },
    ]
}

const ReportBuilder = () => {
    const [reportDataJson, setReportDataJson] = useState<string>(JSON.stringify(defaultReportData, null, 2) || '');
    const [reportHtml, jsonErrors] = useMemo<[string, string]>(() => {
        try {
            const reportData = JSON.parse(reportDataJson);
            const reportHtml = buildReport(reportData);
            return [reportHtml, ''];
        } catch (e: any) {
            console.error(e)
            return ['', e.message];

        }
    }, [reportDataJson]);

    // Put report HTML in an iframe
    return (
        <Column>
            <TextAreaInput
                value={reportDataJson}
                onChange={setReportDataJson}
            />
            <div>
                <h2>JSON Errors</h2>
                <pre>{jsonErrors}</pre>
            </div>
            <div>
                <Row>
                    <h2>Report Preview</h2>
                    <HorizontalSpaceFlex1 />
                    <Button onClick={() => navigator.clipboard.writeText(reportHtml)}>Copy HTML</Button>
                </Row>

                <iframe
                    title="Report Preview"
                    srcDoc={reportHtml}
                    style={{
                        width: '100%',
                        border: 'none',
                        height: '1000px'
                    }}
                />

            </div>
        </Column>
    )
}

export default ReportBuilder;