
export const REPORT_CSS = `
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'IBM Plex Sans', sans-serif;
}

body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    font-size: 16px;
    line-height: 20px;
}

.main-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    background: var(--background);
    gap: 20px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    /*border: 1px solid red;*/
    justify-content: center;
}

.column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    /*border: 1px solid blue;*/
    flex-basis: 0;
    flex-grow: 1;
}

.panel {
    padding: 20px;
    background: var(--panel);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 4px solid var(--border);
}

.title {
    font-size: 24px;
    font-weight: 700;
    color: var(--text);
    text-align: center;
}

.label {
    font-size: 16px;
    font-weight: 700;
    color: var(--text);
    line-height: 20px;
}

.hint {
    font-size: 14px;
    color: var(--hint);
    font-weight: 400;
    line-height: 20px;
}

.section-header {
    font-size: 20px;
    font-weight: 700;
    color: var(--text);
    line-height: 24px;
}

.sentiment {
    font-size: 20px;
    line-height: 24px;
}

.sentiment-bar {
    display: flex;
    overflow: hidden;
    border-radius: 10px;
    height: 20px;
}

.sentiment-bar-positive {
    background: var(--positive);
    flex-basis: 0;
}

.sentiment-bar-neutral {
    background: var(--neutral);
    flex-basis: 0;
}

ul {
    padding-left: 40px;
}
.sentiment-bar-negative {
    background: var(--negative);
    flex-basis: 0;
}

.sentiment-bar-full {
    background: var(--full);
    flex-basis: 0;

}

.sentiment-bar-empty {
    background: var(--empty);
    flex-basis: 0;
}

.sentiment-proportions {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 20px;
    color: var(--text);
}

.vertical-divider {
    border-left: 1px solid var(--border);
}

.margin-below {
    margin-bottom: 20px;
}

.powered-by {
    font-size: 14px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.two-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding-left: 100px;
    padding-right: 100px;
}

.two-columns-item {
    display: flex;
    gap: 20px;
    align-items: center;
}

.barchart {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 2px;
    border-bottom: 2px solid var(--border);
    height: 200px;
    margin-top: 10px;
    margin-bottom: 50px;
    z-index: 2;
}

.barchart-rotate-labels .barchart-bar-label {
    transform: rotate(45deg);
    white-space: nowrap;
}

.barchart-bar-container {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 200px;
    flex-grow: 1;
    border: 2px solid transparent;
}

.barchart-bar-label {
    font-size: 14px;
    color: var(--text);
    text-align: center;
    margin-top: 10px;
    position: absolute;
    top: calc(100% + 10px);
    width: 100%;
}

.barchart-bar-value {
    font-size: 14px;
    color: var(--text);
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.barchart-bar-empty {
    flex-grow: 1;
}

.barchart-bar-positive {
    background: var(--positive);
}

.barchart-bar-neutral {
    background: var(--neutral);
}

.barchart-bar-negative {
    background: var(--negative);
}

.horizontal-divider {
    width: 100%;
    border-bottom: 1px solid var(--border);
}

ol {
    padding-left: 20px;
}

ol li {
    padding-left: 10px;
}

li {
    margin-bottom: 10px;
}

.center {
    align-items: center;
}

.accordion-header {
   display: flex;
   flex-direction: row;
   align-items: center;
}

.collapsed {
    display: none;
}

.accordion-icon {
    font-size: 24px;
    cursor: pointer;
}

.sentiment-scatter {
    position: relative;
    height: 400px;
    margin: 20px;
}

.sentiment-negative-background {
    background: var(--negative);
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
}

.sentiment-positive-background {
    background: var(--positive);
    width: 100%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
}

.sentiment-quadrant {
    background: transparent;
    border: 1px solid var(--text);
    position: absolute;
    width: 50%;
    height: 50%;
}

.sentiment-scatter-label {
    position: absolute;
    text-align: center;
    width: 100px;
}

.sentiment-scatter-point {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 4px solid var(--text);
}

.sentiment-scatter-point:hover .sentiment-scatter-point-label {
    opacity: 1;
}

.sentiment-scatter-point-label {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 5px);
    text-align: center;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
}

.line-chart {
    position: relative;
    height: 400px;
    margin: 20px;
    border-bottom: 1px solid var(--text);
    border-left: 1px solid var(--text);
}

.line-chart-x-label {
    text-align: center;
    position: absolute;
    white-space: nowrap;
    top: 100%;
    transform: translate(-50%, 10px);
    font-size: 12px;
}

.line-chart-vertical-line {
    position: absolute;
    bottom: 0;
    width: 1px;
    height: 100%;
    background: var(--hint);
}

.line-chart-y-label {
    position: absolute;
    left: 0;
    font-size: 12px;
}

.line-chart-key {
    display: flex;
    flex-wrap: wrap;
}

.line-chart-key-item {
    display: flex;
    gap: 10px;
    font-size: 12px;
    align-items: center;
    width: 120px;
    white-space: nowrap;
}

.line-chart-key-item * {
    flex-shrink: 0;
}

.line-chart-key-item-color {
    border: 1px solid var(--text);
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.charts-title {
    font-size: 24px;
    font-weight: 700;
    color: var(--text);
    text-align: center;
    margin-top: 20px;
}
`;

