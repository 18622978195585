import {getScoring, Scoring, Topic} from "../api/scoring";
import React, {FC, useEffect, useMemo, useState} from "react";
import {useAppContext} from "../context/AppContext";
import Panel from "../atoms/Panel";
import {Body, BodyBold} from "../atoms/Typography";
import {Sentiment, toSentiment} from "../model/feedback";
import {Column, Row} from "../atoms/Layout";
import SentimentBar from "../atoms/SentimentBar";
import {VerticalSpace3} from "../atoms/Space";

interface Props {
    channelId: string;
}

interface TopicWithSentiment {
    topic: Topic;
    totalMentions: number;
    positiveMentions: number;
    negativeMentions: number;
    neutralMentions: number;
}

const TopicSentimentChart: FC<Props> = ({ channelId }) => {
    const { feedback, channels,  } = useAppContext();
    const [scoring, setScoring] = useState<Scoring | undefined>(undefined);
    const channel = channels.value?.find(c => c.id === channelId);

    console.log(channel, scoring)

    const topicsWithSentiment = useMemo(() => {
        if (!scoring || !feedback.value) return [];

        const filteredFeedback = feedback.value?.filter(f => !channelId || f.channelId === channelId) ?? [];
        const results: Record<string, TopicWithSentiment> = {}

        scoring.topics.forEach(t => {
            results[t.id] = {
                topic: t,
                totalMentions: 0,
                positiveMentions: 0,
                negativeMentions: 0,
                neutralMentions: 0,
            }
        })

        filteredFeedback.forEach(f => {
            f.analysis.topics.forEach(t => {
                results[t.id].totalMentions++;
                const sentiment = toSentiment(f.analysis.sentimentScore);
                if (sentiment === Sentiment.POSITIVE) {
                    results[t.id].positiveMentions++;
                } else if (sentiment === Sentiment.NEGATIVE) {
                    results[t.id].negativeMentions++;
                } else {
                    results[t.id].neutralMentions++;
                }
            })
        })

        return Object.values(results).sort((a, b) => b.totalMentions - a.totalMentions);
    }, [scoring, feedback.value, channelId]);

    useEffect(() => {
        if (channel) {
            getScoring(channel.scoringId).then(setScoring);
        }
    }, [channel]);

    return <Panel>
        <BodyBold>Topic by Sentiment</BodyBold>
        <VerticalSpace3 />
        <Column gap="small">
            {topicsWithSentiment.map(t => {
                return <Row vcenter>
                    <Body style={{ width: 300, flexShrink: 0, whiteSpace: "nowrap"}}>{t.topic.name} ({t.totalMentions})</Body>
                    <Column size="full">
                        <SentimentBar positiveResponses={t.positiveMentions} neutralResponses={t.neutralMentions} negativeResponses={t.negativeMentions} />
                    </Column>
                </Row>
            })}
        </Column>

    </Panel>
}

export default TopicSentimentChart;