import {FC, useEffect, useState} from "react";
import {Body, Label, Title} from "../../atoms/Typography";
import {getUsers, impersonateUser, User} from "../../api/users";
import Panel from "../../atoms/Panel";
import Loading from "../../atoms/Loading";
import {Column, Row} from "../../atoms/Layout";
import {TextInput} from "../../atoms/Input";
import {HorizontalSpaceFlex1} from "../../atoms/Space";
import Button from "../../atoms/Button";
import styled from "styled-components";
import colors from "../../atoms/colors";

const StyledColumn = styled(Column)`
    & > *:nth-child(odd) {
        background-color: ${colors.background};
    }
`;

const Users: FC = () => {
    const [users, setUsers] = useState<User[] | undefined>(undefined);
    const [search, setSearch] = useState<string>("")
    const matchingUsers = users?.filter(user => user.name.toLowerCase().includes(search.toLowerCase())
        || user.email.toLowerCase().includes(search.toLowerCase()))

    const impersonate = async (user: User) => {
        await impersonateUser(user.email)
        window.location.href = "/dashboard/home"
    }

    useEffect(() => {
        getUsers().then(setUsers)
    }, []);

    return <>
        <Title>Users</Title>
        <Panel>
            <Column gap="medium">
                <Label>Search</Label>
                <TextInput value={search} onChange={setSearch} />
                { users === undefined && <Loading /> }
                { matchingUsers !== undefined && <StyledColumn gap="small">
                    { matchingUsers.map((user) => <Row vcenter key={user.id}>
                        <Body style={{ width: 400 }}>{user.email}</Body>
                        <Body style={{ width: 400 }}>{user.name}</Body>
                        <HorizontalSpaceFlex1 />
                        <Button onClick={() => impersonate(user)}>Impersonate</Button>
                    </Row>) }
                </StyledColumn>}
            </Column>
        </Panel>

    </>
}

export default Users;