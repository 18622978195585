import React, {FC, useEffect, useState} from "react";
import {getUser, User} from "../../api/users";
import {Route, Routes, useNavigate} from "react-router-dom";
import Navigation from "../../atoms/Navigation";
import Logo from "../../atoms/Logo";
import {HorizontalSpaceFlex1} from "../../atoms/Space";
import {BodyBold} from "../../atoms/Typography";
import NavigationLink from "../../atoms/NavigationLink";
import {Column, Row} from "../../atoms/Layout";
import Loading from "../../atoms/Loading";
import Scorings from "./Scorings";
import EditScoring from "./EditScoring";
import ReportBuilder from "./ReportBuilder";
import Users from "./Users";

const RedirectToHome: FC = () => {
    const navigate = useNavigate()
    useEffect(() => {
        navigate('/admin/home')
    }, [])

    return <div>Redirecting...</div>
}

const Admin: FC = () => {
    const [user, setUser] = useState<User | undefined | null>();
    const navigate = useNavigate();

    useEffect(() => {
        getUser().then((user) => {
            if (user === null) {
                navigate('/login')
                return
            }

            if (!user?.roles.includes('ADMIN')) {
                navigate('/dashboard/home')
                return
            }

            setUser(user)
        })
    }, []);

    if (!user) {
        return <Row size="full" center><Loading size="large" /></Row>
    }

    return (<Column size="full" padding="large" gap="large">
        <Navigation>
            <Logo />
            <BodyBold>Admin Area</BodyBold>
            <HorizontalSpaceFlex1 />
            <Navigation>
                <NavigationLink to="/admin/home">Home</NavigationLink>
                <NavigationLink to="/admin/users">Users</NavigationLink>
                <NavigationLink to="/admin/scoring">Scoring Systems</NavigationLink>
                <NavigationLink to="/admin/report-builder">Report Builder</NavigationLink>
            </Navigation>
            <HorizontalSpaceFlex1 />
        </Navigation>
        <Routes>
            <Route path="/home" element={<div>Admin Home</div>} />
            <Route path="/users" element={<Users />} />
            <Route path="/scoring" element={<Scorings />} />
            <Route path="/scoring/new" element={<EditScoring />} />
            <Route path="/scoring/:scoringId" element={<EditScoring />} />
            <Route path="/report-builder" element={<ReportBuilder />} />
            <Route path="*" element={<RedirectToHome/>} />
        </Routes>

    </Column>)
}

export default Admin