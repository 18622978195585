import {REPORT_CSS} from "./reportCss";
import {
    ActionPlan,
    ActionPlanSection, BarChart, Chart,
    KeyTopicsChart, LineChart, NetPromoterScoreChart,
    ReportContent,
    ResponseProfile,
    ScoreIndex, SentimentQuadrantScatterChart,
    TopicSentimentChart
} from "./types";
import {v4} from "uuid";

const ACCORDION_SCRIPT = `
const registerAccordions = () => {
    accordionHeaders = document.querySelectorAll('[data-open-accordion]');
    
    accordionHeaders.forEach(header => {
        header.addEventListener('click', () => {
            const accordion = document.getElementById(header.dataset.openAccordion);
            accordion.classList.toggle('collapsed');
            header.querySelector('.accordion-icon').textContent = accordion.classList.contains('collapsed') ? '+' : '-';
        })
    })
}


document.addEventListener('DOMContentLoaded', registerAccordions);
`

const colorDefinitionsCSS = (reportContent: ReportContent) => {
    return `
        :root {
            --text: ${reportContent.colors.text};
            --hint: ${reportContent.colors.hint};
            --border: ${reportContent.colors.border};
            --background: ${reportContent.colors.background};
            --panel: ${reportContent.colors.panel};
            --brand: ${reportContent.colors.brand};
            --negative: ${reportContent.colors.negative};
            --neutral: ${reportContent.colors.neutral};
            --positive: ${reportContent.colors.positive};
            --empty: ${reportContent.colors.empty};
            --full: ${reportContent.colors.full};
        }
    `
}

const reportContainer = (reportContent: ReportContent, children: string) => {
    return `
        <html lang="en">
            <head>
                <title>${reportContent.title}</title>
                <style>
                    ${REPORT_CSS}
                    ${colorDefinitionsCSS(reportContent)}
                </style>
                <script>
                    ${ACCORDION_SCRIPT}
                </script>
            </head>
            <body>
                <div class="main-content">
                    ${children}
                    ${poweredBy()}
                </div>
            </body>
        </html>
    `
}

const poweredBy = () => `
    <div class="row" style="color: var(--panel)">
        <div class="column center">
            <div>Powered by <strong>symantiq</strong>_AI</div>
        </div>
    </div>
`;

const formatText = (text: string) => {
    // Anything surrounded in ** is bold
    // Newlines replaced with <br/>
    // Replace non-html characters with & codes
    return text
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
        .replace('\u2014', '-')
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;')
        .replace(/‘/g, '&lsquo;')
        .replace(/’/g, '&rsquo;')
        .replace(/“/g, '&ldquo;')
        .replace(/”/g, '&rdquo;')
        .replace(/–/g, '&ndash;')
        .replace(/\n/g, '<br/>')
}

const titlePanel = (title: string) => {
    return `
        <div class="panel title row">
            ${title}
        </div>
    `
}

const executiveSummary = (summary: string) => `
        <div class="row panel">
            <div class="column">
                <div class="section-header">Executive Summary</div>
                <div>${formatText(summary)}</div>
            </div>
        </div>
    `

const videoPanel = () => `
    <div class="row panel">
        <div class="column">
            <video controls width="530">
                <source src="./video.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </div>
    </div>
`

const actionPlanSection = (section: ActionPlanSection) => `
    <div class="section-header">${section.title}</div>
    ${section.strength ? `<div><b>Strength:</b> ${section.strength}</div>` : ''}
    ${section.challenge ? `<div><b>Challenge:</b> ${section.challenge}</div>` : ''}
    ${section.action ? `<div><b>Action:</b> ${section.action}</div>` : ''}
    ${section.immediateActions.length > 0 ? `
        <div class="label">Immediate Actions:</div>
        <ol>
            ${section.immediateActions.map(action => `
                <li>
                    <b>${action.title}:</b> ${formatText(action.description)}
                </li>
            `).join('')}
        </ol>
    ` : '' }
    ${section.nextLevelActions.length > 0 ? `
        <div class="label">Next-Level Actions:</div>
        <ol start="${section.immediateActions.length + 1}">
            ${section.nextLevelActions.map(action => `
                <li>
                    <b>${action.title}:</b> ${formatText(action.description)}
                </li>
            `).join('')}
        </ol>
    ` : '' }
    ${section.impact ? `<div><b>Impact:</b> ${formatText(section.impact)}</div>` : ''}
`;

const actionPlanPanel = (actionPlan: ActionPlan) => `
    <div class="row panel">
        <div class="column">
            <div class="section-header">Action Plan</div>
            ${actionPlan.title ? `<div class="label">${actionPlan.title}</div>` : ''}
            <div>${formatText(actionPlan.introduction)}</div>
            <div class="horizontal-divider"></div>
            ${actionPlan.sections.map(actionPlanSection).join('<div class="horizontal-divider"></div>')}
            ${actionPlan.conclusion ? `
                <div class="horizontal-divider"></div>
                <div class="section-header">Conclusion</div>
                <div>${formatText(actionPlan.conclusion)}</div>
            ` : ''}
        </div>
    </div>
`

const responseProfilePanel = (responseProfile: ResponseProfile) => {
    const totalResponses = responseProfile.negativeResponses + responseProfile.neutralResponses + responseProfile.positiveResponses;
    const percentPositive = ((responseProfile.positiveResponses / totalResponses) * 100).toFixed(0);
    let percentNeutral = ((responseProfile.neutralResponses / totalResponses) * 100).toFixed(0);
    const percentNegative = ((responseProfile.negativeResponses / totalResponses) * 100).toFixed(0);

    // If the percentages don't add up to 100, give the extra to neutral
    if (parseInt(percentPositive) + parseInt(percentNeutral) + parseInt(percentNegative) < 100) {
        percentNeutral = (parseInt(percentNeutral) + 1).toString();
    }

    return `
        <div class="row panel">
            <div class="column">
                <div class="section-header">Sentiment Response Profile</div>
                <div class="sentiment">${responseProfile.overallSentiment}</div>
                <div class="sentiment-bar">
                    <div class="sentiment-bar-negative" style="flex-grow: ${responseProfile.negativeResponses}"></div>
                    <div class="sentiment-bar-neutral" style="flex-grow: ${responseProfile.neutralResponses}"></div>
                    <div class="sentiment-bar-positive" style="flex-grow: ${responseProfile.positiveResponses}"></div>
                </div>
                <div class="sentiment-proportions">
                    <div>${percentNegative}% Negative</div>
                    <div class="vertical-divider"></div>
                    <div>${percentNeutral}% Neutral</div>
                    <div class="vertical-divider"></div>
                    <div>${percentPositive}% Positive</div>
                </div>
                ${responseProfile.description ? `<div>${formatText(responseProfile.description)}</div>` : ''}
            </div>
        </div>
    `;
};

const scoreIndexPanel = (scoreIndex: ScoreIndex) => {
    return `
        <div class="row panel">
            <div class="column">
                <div class="section-header">${scoreIndex.title}</div>
                <div>${formatText(scoreIndex.description)}</div>
                ${scoreIndex.scores.map(score => `
                    <div class="label">${score.title}: ${score.score}/10 <span class="hint">(${score.basedOn})</span></div>
                    <div class="sentiment-bar">
                        <div class="sentiment-bar-${score.color}" style="flex-grow: ${score.score}"></div>
                        <div class="sentiment-bar-empty" style="flex-grow: ${10 - score.score}"></div>
                    </div>
                    <div>
                        ${formatText(score.description)}
                    </div>
                `).join("")}
            </div>
        </div>
    `;
}

const mainColumns = (reportContent: ReportContent) => `
    <div class="row">
        <div class="column">
            ${ reportContent.executiveSummary ? executiveSummary(reportContent.executiveSummary): ''}
            ${videoPanel()}
            ${actionPlanPanel(reportContent.actionPlan)}
        </div>
        <div class="column">
            ${responseProfilePanel(reportContent.responseProfile)}
            ${scoreIndexPanel(reportContent.scoreIndex)}
        </div>
    </div>
`

const keyTopicsChart = (chart: KeyTopicsChart) => `
        <div>${formatText(chart.description)}</div>
        ${chart.topics.map(topic => `
            <div class="row center">
                <div style="width: 240px">
                    ${topic.title} (${topic.responses})
                </div>
                <div class="sentiment-bar" style="flex-grow: 1">
                    <div class="sentiment-bar-full" style="flex-grow: ${topic.responses}"></div>
                    <div class="sentiment-bar-empty" style="flex-grow: ${chart.totalResponses - topic.responses}"></div>
                </div>
            </div>
        `).join('')}
`

const topicSentimentChart = (chart: TopicSentimentChart) => `
    <div>${formatText(chart.description)}</div>
    ${chart.topics.map(topic => {
        const totalResponses = topic.negativeResponses + topic.neutralResponses + topic.positiveResponses;
        let bar = `<div class="sentiment-bar-empty" style="flex-grow: 1"></div>`
        if (totalResponses > 0) {
            bar = `
                    <div class="sentiment-bar-negative" style="flex-grow: ${topic.negativeResponses}"></div>
                    <div class="sentiment-bar-neutral" style="flex-grow: ${topic.neutralResponses}"></div>
                    <div class="sentiment-bar-positive" style="flex-grow: ${topic.positiveResponses}"></div>
            `
        }
        
        return `
            <div class="row center">
                <div style="width: 240px">
                    ${topic.title}
                </div>
                <div class="sentiment-bar" style="flex-grow: 1">
                    ${bar}
                </div>
            </div>
        `
    }).join("")}
`

const barChart = (chart: BarChart) => {
    const highestBar = chart.data.reduce((acc, curr) => curr.value > acc ? curr.value : acc, 0);
    const labelStyle = chart.labelStyle || 'horizontal';
    const longestLabel = chart.data.reduce((acc, curr) => curr.label.length > acc ? curr.label.length : acc, 0);
    const marginBottom = labelStyle === 'horizontal' ? 50 : 25 + (longestLabel * 5);

    return `
        <div class="barchart ${labelStyle === 'angled' ? 'barchart-rotate-labels' : ''}" style="margin-bottom: ${marginBottom}px">
            ${chart.data.map(bar => `
                <div class="barchart-bar-container">
                    <div class="barchart-bar-empty" style="flex-grow: ${highestBar - bar.value}"></div>
                    <div class="barchart-bar-${bar.color}" style="flex-grow: ${bar.value}"></div>
                    <div class="barchart-bar-label">${bar.label}</div>
                    ${chart.showNumbers ? `<div class="barchart-bar-value">${parseFloat(bar.value.toFixed(1))}</div>` : ''}
                </div>
            `).join('')}
        </div>
    `
}

const netPromoterScoreChart = (chart: NetPromoterScoreChart) => {
    const totalResponses = chart.detractors + chart.passives + chart.promoters;
    if (totalResponses === 0) {
        return "No responses to calculate NPS"
    }
    const percentPromoters = (chart.promoters / totalResponses) * 100
    const percentDetractors = (chart.detractors / totalResponses) * 100
    const netPromoterScore = (percentPromoters - percentDetractors);
    const symbol = netPromoterScore > 0 ? '+' : '';
    const formattedNetPromoterScore = symbol + netPromoterScore.toFixed(1);

    return `
        <div class="label">Net Promoter Score: ${formattedNetPromoterScore}</div>
        <div class="sentiment-bar">
            <div class="sentiment-bar-negative" style="flex-grow: ${chart.detractors}"></div>
            <div class="sentiment-bar-neutral" style="flex-grow: ${chart.passives}"></div>
            <div class="sentiment-bar-positive" style="flex-grow: ${chart.promoters}"></div>
        </div>
        <div class="sentiment-proportions">
            <div>${percentDetractors.toFixed(0)}% Detractors</div>
            <div style="flex-grow: 1"></div>
            <div>${percentPromoters.toFixed(0)}% Promoters</div>
        </div>
    `
}

const sentimentQuadrantScatterChart = (chart: SentimentQuadrantScatterChart) => {

    return `
        <div>${formatText(chart.description)}</div>
        <div class="sentiment-scatter">
            <div class="sentiment-negative-background"></div>
            <div class="sentiment-positive-background"></div>
            <div class="sentiment-quadrant" style="bottom: 0; left: 0"></div>
            <div class="sentiment-quadrant" style="bottom: 0; right: 0"></div>
            <div class="sentiment-quadrant" style="top: 0; left: 0"></div>
            <div class="sentiment-quadrant" style="top: 0; right: 0"></div>
            <div class="sentiment-scatter-label" style="top: 0; right: 100%; transform: translate(-50%, -50%) rotate(-90deg) translate(-40px, 80px)">Positive</div>
            <div class="sentiment-scatter-label" style="bottom: 0; right: 100%; transform: translate(-50%, -50%) rotate(-90deg) translate(20px, 80px)">Negative</div>
            <div class="sentiment-scatter-label" style="top: 100%; left: 0; transform: translate(-50%, -50%) translate(50px, 20px)">Infrequently Mentioned</div>
            <div class="sentiment-scatter-label" style="top: 100%; right: 0; transform: translate(-50%, -50%) translate(60px, 20px)">Frequently Mentioned</div>
            ${chart.points.map(point => `
                <div class="sentiment-scatter-point" style="bottom: calc(${point.y * 100}% - 5px); left: calc(${point.x * 100}% - 5px)">
                    <div class="sentiment-scatter-point-label">${point.title}</div>
                </div>
            `).join('')}
        </div>
    `
}

// x1, y1, x2, y2 are all percentages from 0 to 1
const lineSegment = (x1: number, y1: number, x2: number, y2: number, color: string) => {
    return `<line x1="${x1 * 400}" y1="${y1 * 400}" x2="${x2 * 400}" y2="${y2 * 400}" stroke="${color}" />`
}

const lineChart = (chart: LineChart) => {
    const xValueCount = chart.xAxis.values.length;
    const xSegmentWidth = 100 / (xValueCount - 1);
    const xPositions = chart.xAxis.values.map((_, i) => i * xSegmentWidth / 100);
    const getYPosition = (y: number) => {
        return 1 - (y - chart.yAxis.min) / (chart.yAxis.max - chart.yAxis.min);
    }

    return `
        <div>${formatText(chart.description)}</div>
        <div class="line-chart">
            <svg viewBox="0 0 400 400" style="width: 100%; height: 100%;" preserveAspectRatio="none">
                ${chart.lines.map((line, i) => {
                    return line.values.map((value, j) => {
                        if (j === 0) {
                            return ''
                        }
                        return lineSegment(xPositions[j - 1], getYPosition(line.values[j - 1]), xPositions[j], getYPosition(value), line.color)
                    }).join('')
                }).join('')}
            </svg>
            ${chart.xAxis.values.map((value, i) => {
                return `
                    <div class="line-chart-x-label" style="left: ${xPositions[i] * 100}%">${value}</div>
                    <div class="line-chart-vertical-line" style="left: ${xPositions[i] * 100}%"></div>
                `
            }).join('')}
            <div class="line-chart-y-label" style="top: 0; transform: translate(-45px, -50%) rotate(-90deg) translate(-30px, 0)">Very Positive</div>
            <div class="line-chart-y-label" style="top: 50%; transform: translate(-45px, -50%) rotate(-90deg); font-weight: 700">Sentiment</div>
            <div class="line-chart-y-label" style="bottom: 0; transform: translate(-45px, -50%) rotate(-90deg) translate(20px, 0)">Very Negative</div>
        </div>    
        <div class="line-chart-key">
            ${chart.lines.map(line => `<div class="line-chart-key-item">
                <div class="line-chart-key-item-color" style="background: ${line.color}"></div>
                <div>${line.title}</div>
            </div>`).join('')}
        </div>
    `;
}


const chart = (chart: Chart) => {
    if (chart.type === "title") {
        return `<div class="charts-title">${chart.title}</div>`
    }

    let result = "";
    switch (chart.type) {
        case 'keyTopics':
            result =  keyTopicsChart(chart);
            break;
        case 'topicSentiment':
            result = topicSentimentChart(chart);
            break;
        case 'bar':
            result = barChart(chart);
            break;
        case 'netPromoterScore':
            result = netPromoterScoreChart(chart);
            break;
        case 'sentimentQuadrantScatter':
            result = sentimentQuadrantScatterChart(chart);
            break;
        case 'line':
            result = lineChart(chart);
            break;
    }

    if (chart.collapsible) {
        const id = v4();

        return `
            <div class="panel column">
                <div class="accordion-header" data-open-accordion="${id}">
                    <div class="label">${chart.title}</div>
                    <div style="flex-grow: 1"></div>
                    <div class="accordion-icon">${chart.startCollapsed ? '+' : '-'}</div>
                </div>
                <div class="column ${chart.startCollapsed ? 'collapsed' : ''}" id="${id}">
                    ${result}
                </div>
            </div>
        `;

    }

    return `<div class="panel column">
        <div class="label">${chart.title}</div>
        ${result}
    </div>`
}

const charts = (reportContent: ReportContent) => `
    <div class="panel column">
        <div class="title row">
            ${reportContent.chartsSectionTitle}
        </div>
        <div class="row" style="align-items: flex-start">
            <div class="column">
               ${reportContent.leftColumnCharts.map(c => chart(c)).join('')}
            </div>
            <div class="column">
                ${reportContent.rightColumnCharts.map(c => chart(c)).join('')}
            </div>
        </div>
        <div class="row">
            <div class="column">
                ${reportContent.wideCharts.map(c => chart(c)).join('')}
            </div>
        </div>
    </div>
`;

export const buildReport = (reportContent: ReportContent) => {
    return reportContainer(reportContent, `
        ${titlePanel(reportContent.title)}
        ${mainColumns(reportContent)}
        ${charts(reportContent)}
    `)
}