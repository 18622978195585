import {FC} from "react";
import styled from "styled-components";
import colors from "./colors";
import {SENTIMENT_COLORS} from "../model/feedback";

interface Props {
    positiveResponses: number;
    neutralResponses: number;
    negativeResponses: number;
}

const Bar = styled.div`
    height: 20px;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    background-color: ${colors.secondary};
`;

const PositiveSegment = styled.div`
    background-color: ${SENTIMENT_COLORS.POSITIVE};
`;

const NeutralSegment = styled.div`
    background-color: ${SENTIMENT_COLORS.NEUTRAL};
`;

const NegativeSegment = styled.div`
    background-color: ${SENTIMENT_COLORS.NEGATIVE};
`;


const SentimentBar: FC<Props> = ({ positiveResponses, neutralResponses, negativeResponses }) => {

    const totalResponses = positiveResponses + neutralResponses + negativeResponses;
    if (totalResponses === 0) return <Bar />

    const positivePercentage = positiveResponses / totalResponses * 100;
    const neutralPercentage = neutralResponses / totalResponses * 100;
    const negativePercentage = negativeResponses / totalResponses * 100;

    return <Bar>
        <PositiveSegment style={{ width: `${positivePercentage}%` }} />
        <NeutralSegment style={{ width: `${neutralPercentage}%` }} />
        <NegativeSegment style={{ width: `${negativePercentage}%` }} />
    </Bar>
}

export default SentimentBar;