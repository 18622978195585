import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getChannelPublicInformation, ChannelPublicInformation } from '../../api/channels';
import { Row } from '../../atoms/Layout';
import Loading from '../../atoms/Loading';
import FeedbackWizard from '../templates/FeedbackWizard';
import { toFeedbackTheme } from '../theme';
import { submitFeedback } from '../../api/feedback';


const getQueryParams = (): Record<string, string> => {
    const result: Record<string, string> = {};
    const entries = new URLSearchParams(window.location.search).entries();
    for (const [key, value] of entries) {
        result[key] = value;
    }
    return result;
}

// Remove s_ prefix, replace underscores with spaces, capitalize words
// s_employee_name -> Employee Name
// Also replace the whole word Id with ID, making sure it's not part of another word
const formatKey = (key: string): string => {
    key = key.substring(2);
    key = key.replace(/_/g, " ");
    key =  key.replace(/\b\w/g, l => l.toUpperCase());
    key = key.replace(/\bId\b/g, "ID");
    return key;
}

const getExtraValues = (): Record<string, string> => {
    const queryParams = getQueryParams();
    const result: Record<string, string> = {};
    for (const key in queryParams) {
        if (key.startsWith("s_")) {
            const formattedKey = formatKey(key)
            result[formattedKey] = queryParams[key];
        }
    }
    return result;
}

const ChannelFeedback: FC = () => {
    const { id } = useParams();
    const [channelInfo, setChannelInfo] = useState<ChannelPublicInformation | null>(null);
    const extraValues = getExtraValues();
    console.log({ extraValues })

    React.useEffect(() => {
        if (id) {
            getChannelPublicInformation(id).then(setChannelInfo)
                .catch(() => {
                    window.location.href = '/';
                })
        }
    }, [id])

    if (!channelInfo || !id) {
        return <Row size="full" center>
            <Loading size="large" />
        </Row>
    }

    let logoUrl = "";
    if (channelInfo.hasLogo) {
        logoUrl = `/api/channel/${id}/logo`;
    }
    if (channelInfo.logoOverrideUrl) {
        logoUrl = channelInfo.logoOverrideUrl;

    }

    return <FeedbackWizard theme={toFeedbackTheme(channelInfo.feedbackPage, channelInfo.prompt, logoUrl)} onSubmit={value => submitFeedback(id, value, extraValues)} />
}

export default ChannelFeedback;
